import { FC } from "react";
import { CellContext } from "@tanstack/react-table";
import { IStockTableItem } from "./stockPage";
import { useReactiveVar } from "@apollo/client";
import { favoriteSkus } from "../../store/skus.store";
import { chosenStore } from "../../store/stores.store";
import { updateFavoriteSkus } from "../../store/indexDB/skus.db";

import Star from "../../assets/icons/star.svg?react";
import StarFilled from "../../assets/icons/starFilled.svg?react";

export const SkuStockCell: FC<CellContext<IStockTableItem[], unknown>> = ({
  getValue,
}) => {
  const favorite = useReactiveVar(favoriteSkus);
  const store = useReactiveVar(chosenStore);
  const sku = getValue().toString();
  const isFavorite = favorite?.[store?.id]?.includes(sku);

  const handleFavorite = () => {
    if (store?.id && !favorite?.[store.id]?.includes(sku)) {
      updateFavoriteSkus({
        ...favorite,
        [store.id]: favorite?.[store.id]?.length
          ? [...favorite[store.id], sku]
          : [sku],
      });
    } else {
      updateFavoriteSkus({
        ...favorite,
        [store.id]: favorite[store.id]?.filter((i) => i !== sku),
      });
    }
  };

  return (
    <span className="stock-cell-text">
      {sku}{" "}
      <button
        className={`stock-cell-favorite-btn stock-cell-favorite-btn-active__${isFavorite}`}
        onClick={handleFavorite}
      >
        {isFavorite ? <StarFilled /> : <Star />}
      </button>
    </span>
  );
};
