import { indexDBInstance } from "./index";
import { favoriteSkus } from "../skus.store";

export const FAVORITE_SKUS_KEY = "favoriteSkus";
export const FAVORITE_SKUS_REHYDRATE_INFO = {
  reactiveVar: favoriteSkus,
  key: FAVORITE_SKUS_KEY,
};

export const updateFavoriteSkus = (
  newStore: ReturnType<typeof favoriteSkus>,
) => {
  favoriteSkus(newStore);
  indexDBInstance.setItem(FAVORITE_SKUS_KEY, newStore);
};
